/* eslint-disable jsx-a11y/anchor-is-valid */
import {Dispatch, SetStateAction, useState} from 'react'
import {FormikValues} from 'formik'
import {ISettings} from '../../../../../_metronic/helpers/custom/tsHelpers/profileHelpers'
import {Portal} from '../../../../../_metronic/partials'
import {TrackItemCard} from './TrackItemCard'
import {CreateTrackContentCard} from './_modals/CreateTrackContentCard'
import {CreateItemCard} from '../../../../modules/custom/sections/CreateItemCard'
import {ITrackProds, Track} from '../../core/_models/_tracks-models'
import {FormattedMessage, useIntl} from 'react-intl'

interface Props {
  track: Track
  setCurrTrack: (obj: Track) => void
  isArchive: boolean
  setDisabledRadios: Dispatch<SetStateAction<boolean | undefined>>
}

export function TrackContent({track, setCurrTrack, isArchive, setDisabledRadios}: Props) {
  const {prods, track_package} = track
  const [modalOpen, setModalOpen] = useState(false)
  const intl = useIntl()

  return (
    <>
      {modalOpen && (
        <Portal
          children={
            <CreateTrackContentCard
              track={track}
              setIsOpen={setModalOpen}
              isOpen={modalOpen}
              setCurrTrack={setCurrTrack}
              setDisabledRadios={setDisabledRadios}
            />
          }
          className='modal-root'
        />
      )}
      <div className='d-flex flex-wrap flex-stack mb-10 '>
        <h3 className='fw-bolder my-2'>
          <FormattedMessage id='TRACK_PAGE.CONTENT_TAB.HEADING' />
        </h3>
      </div>
      <div className='row g-6 g-xl-9'>
        {prods.map((item: ITrackProds) => (
          <TrackItemCard
            key={item.id}
            isArchive={isArchive}
            items={prods}
            item={item}
            track={track}
            setCurrTrack={setCurrTrack}
            setDisabledRadios={setDisabledRadios}
          />
        ))}{' '}
        {isArchive === false && track_package.length === 0 && (
          <CreateItemCard
            setModalOpen={setModalOpen}
            itemName={intl.formatMessage({id: 'TRACK_PAGE.CONTENT_TAB.GOODS_ITEM_NAME'})}
          />
        )}
      </div>
    </>
  )
}
