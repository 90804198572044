/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useMutation} from 'react-query'
import {statusColor} from '../../../../../../../_metronic/helpers/custom/funcs/statusColor'
import {usePageData} from '../../../../../../../_metronic/layout/core'
import {Portal} from '../../../../../../../_metronic/partials'
import {useAuth} from '../../../../../../modules/auth'
import {ActionConfirmation} from '../../../../../../modules/custom/modals/ActionConfirmation'
import {useQueryResponse} from '../../../../core/QueryResponseProvider'
import {Buyout} from '../../../../core/_models/_buyout-models'
import {confirmBuyout} from '../../../../core/_requests'

type Props = {
  buyout: Buyout
}

const BuyoutStatusCell = ({buyout}: Props) => {
  const {status, summary, user_confirm, no_money, id} = buyout
  const {refetch} = useQueryResponse()
  // const [show, setShow] = useState(false)

  const {currentUser} = useAuth()
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  const intl = useIntl()
  const confirmMutation = useMutation<any, Error>(
    async () => {
      return await confirmBuyout(id)
    },
    {
      onSuccess: () => {
        // setShow(true)
        refetch()
      },
      onSettled: () => {
        setConfirmModalOpen(false)
      },
      onError: (error) => {
        console.log(error)
      },
    }
  )

  const {setPageCustomData, addNotification} = usePageData()
  const moneyForPaymentConfirmation = no_money === true && currentUser?.vip === 0 ? false : true
  return (
    <div className='text-center'>
      {confirmModalOpen && (
        <Portal
          children={
            <ActionConfirmation
              actionName={intl.formatMessage({id: 'BUYOUT_PAGE.CONF_BUYOUT_CONF'})}
              setModalOpen={setConfirmModalOpen}
              handleAction={() => confirmMutation.mutate()}
              id={id}
              isOpen={confirmModalOpen}
            />
          }
          className='modal-root'
        />
      )}
      <p className='text-dark fw-bold text-hover-primary d-block fs-6 p-0 m-0'>{status}</p>{' '}
      {
        // isArchive === false &&
        summary > 0 && user_confirm === 0 && (
          <div className='mt-2'>
            {' '}
            <span
              className={`p-1 cursor-pointer fw-semobold  StatusBar__status ${statusColor(
                moneyForPaymentConfirmation
              )}`}
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_checkout'
              onClick={() => {
                if (moneyForPaymentConfirmation === false) {
                  if (currentUser?.can_add_balance === 0) {
                    addNotification({
                      status: 'danger',
                      body: 'GENERAL.TOOLTIPS.PROH_PAYMENT',
                    })
                  } else {
                    setPageCustomData({
                      debts: 0,
                      checkoutShow: true,
                      checkoutSum:
                        currentUser?.balance && currentUser?.balance > 0
                          ? (summary - currentUser.balance).toFixed(2)
                          : summary,
                      confirmRequest: confirmMutation.mutate,
                      confirmId: id,
                      showPayAll: true,
                    })
                  }
                } else {
                  setConfirmModalOpen(true)
                }
              }}
            >
              <FormattedMessage
                id='GENERAL.BUTTONS.CONFIRM_PAYMENT'
                values={{sum: currentUser?.vip === 0 ? `$${summary}` : ''}}
              />
            </span>
          </div>
        )
      }
    </div>
  )
}

export {BuyoutStatusCell}
