import {
  IBuyoutFormProds,
  IBuyoutProds,
} from '../../../../app/pages/buyouts/core/_models/_buyout-models'
import {
  ITrackFormProds,
  ITrackProds,
} from '../../../../app/pages/tracks/core/_models/_tracks-models'
import {getTrackBrandsByCat} from '../../../../app/pages/tracks/core/_requests'
import {GoodsBrandsQueryResponse} from '../tsHelpers/categoriesHelpers'

export const reformTrackProducts = (prods: ITrackFormProds[]): ITrackProds[] => {
  const reformed = prods.map(
    ({name, qty, cost, total, brand, weight, repack, used, user_name, model}) => {
      return {
        name: name.label,
        qty: qty,
        cost: cost,
        total: total,
        category: name.value,
        brand: brand.id ? +brand.id : null,
        wgt: name?.require_weight && +name.require_weight === 1 ? weight : null,
        repack: repack === true ? 1 : 0,
        used: used === true ? 1 : 0,
        user_name,
        model,
      }
    }
  )
  // @ts-ignore

  return reformed
}

export const reformTrackProductsFromBackend = async (prods: ITrackProds[]) => {
  let fields: ITrackFormProds[] = []

  const promises = prods.map(
    async ({qty, cost, total, wgt, category, brand, repack, group, used, model, user_name}) => {
      let brands: GoodsBrandsQueryResponse = []

      if (category?.id) brands = await getTrackBrandsByCat(category?.id)
      return {
        group: {label: group?.name || '', value: group?.id || ''},
        name: {
          ...category,
          label: category?.name,
          value: category?.id,
          require_invoice: +category?.require_invoice,
          brands,
        },
        brand: {...brand, label: brand?.name || '', value: brand?.id || ''},
        qty,
        cost,
        total,
        repack: !!repack && +repack === 1 ? true : false,
        weight: wgt || '',
        used: +used === 1 ? true : false,
        model: model || '',
        user_name: user_name || '',
      }
    }
  )

  fields = await Promise.all(promises)

  return fields
}

export const reformOneTrackProduct = (prod: ITrackFormProds): ITrackProds => {
  const {name, qty, cost, total, id, brand, weight, repack, used, user_name, model} = prod

  const reformed = {
    name: name.label,
    qty: qty,
    cost: cost,
    total: total,
    category: name.value,
    id: id || null,
    brand: brand.id ? +brand.id : null,
    wgt: name?.require_weight && +name.require_weight === 1 ? weight : null,
    repack: repack === true ? 1 : 0,
    used: used === true ? 1 : 0,
    user_name,
    model,
  }
  // @ts-ignore
  return reformed
}

export const reformBuyoutProducts = (prods: IBuyoutFormProds[]): IBuyoutProds[] => {
  const reformed = prods.map(
    ({
      name,
      qty,
      cost,
      total,
      check_control,
      check_control_desc,
      sku,
      color,
      size,
      link,
      alt_link,
      brand,
      repack,
      weight,
      used,
      user_name,
      model,
    }) => {
      return {
        name: name.label,
        qty,
        cost,
        total,
        category: name.value,
        check_control: check_control === true ? 1 : 0,
        check_control_desc: check_control_desc || '',
        link,
        alt_link: alt_link || null,
        sku: sku || null,
        color: color || null,
        size: size || null,
        unavaliable: 0,
        brand: brand.id ? +brand.id : null,
        wgt: name?.require_weight && +name.require_weight === 1 ? weight : null,
        repack: repack === true ? 1 : 0,
        used: used === true ? 1 : 0,
        user_name,
        model,
      }
    }
  )
  // @ts-ignore
  return reformed
}

export const reformOneBuyoutProduct = (prod: IBuyoutFormProds): IBuyoutProds => {
  const {
    name,
    qty,
    cost,
    total,
    check_control,
    check_control_desc,
    sku,
    color,
    size,
    link,
    alt_link,
    id,
    brand,
    weight,
    repack,
    used,
    user_name,
    model,
  } = prod

  const reformed = {
    name: name.label,
    qty: qty,
    cost: cost,
    total: total,
    category: name.value,
    check_control: check_control === true ? 1 : 0,
    check_control_desc: check_control_desc || '',
    link,
    alt_link: alt_link || null,
    sku: sku || null,
    color: color || null,
    size: size || null,
    unavaliable: 0,
    id: id || null,
    brand: brand.id ? +brand.id : null,
    wgt: name.require_weight && +name.require_weight === 1 ? weight : null,
    repack: repack === true ? 1 : 0,
    used: used === true ? 1 : 0,
    user_name,
    model,
  }
  // @ts-ignore

  return reformed
}
