import {FC, useRef, useState} from 'react'
import {Overlay, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'
import {KTSVG, toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {copyToClipboard} from '../../../../../../../_metronic/helpers/custom/funcs/copyToClipboard'
import {usePageData} from '../../../../../../../_metronic/layout/core'
import {ImageLightbox} from '../../../../../../modules/custom/images/ImageLightbox'
import {useListView} from '../../../../core/ListViewProvider'
import {Track} from '../../../../core/_models/_tracks-models'

type Props = {
  track: Track
}

const TrackNumberCell: FC<Props> = ({track}) => {
  const {
    track_code,
    receiver,
    type_delive,
    dispatch,
    preview_files,
    track_package,
    missing_fields,
    wp_data,
  } = track
  const {setItemIdForUpdate, itemIdForUpdate} = useListView()

  const [currentImage, setCurrentImage] = useState(0)

  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const handleClick = async () => {
    if (itemIdForUpdate === track.id) {
      setItemIdForUpdate(undefined)
    } else {
      await setItemIdForUpdate(undefined)
      setItemIdForUpdate(track.id)
    }
  }

  const getFalseFields = (entries: Record<string, boolean>) => {
    return Object.entries(entries).filter(([key, value]) => value === false)
  }

  const falseEntries = getFalseFields(track.field_state)

  const intl = useIntl()

  const [show, setShow] = useState(false)
  const target = useRef(null)

  const missingTooltip = (
    <div className='fs-7 fw-normal' id='tooltip'>
      <FormattedMessage id='TRACKS.PAGE.LIST.NUMBER.WARNING' />
      <ul className='m-0'>
        {falseEntries.map(([key, _]) => (
          <li key={key}>
            <FormattedMessage id={`TRACKS.PAGE.LIST.NUMBER.WARNING_${key.toUpperCase()}`} />
          </li>
        ))}
      </ul>
    </div>
  )

  const codeValueRef = useRef(null)

  return (
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-45px me-5'>
        {preview_files && preview_files.length > 0 ? (
          <div className='cursor-zoom-in'>
            <div className='symbol symbol-fixed position-relative'>
              <img
                src={preview_files[0]}
                width='45'
                height='45'
                alt='track'
                onClick={openLightbox}
                style={{objectFit: 'contain'}}
              />

              <ImageLightbox
                viewerIsOpen={viewerIsOpen}
                closeLightbox={closeLightbox}
                currentImage={currentImage}
                images={preview_files.map((item, index) => {
                  return {src: item, title: `${track_code}-${index + 1}`}
                })}
              />
            </div>
          </div>
        ) : (
          <img src={toAbsoluteUrl('/media/icons/duotune/ecommerce/ecm010.svg')} alt='track' />
        )}{' '}
      </div>
      <div className='d-flex justify-content-start flex-column'>
        <div className='d-flex align-items-center'>
          <div className='d-flex m-2 mb-1 cursor-pointer h-25px' onClick={handleClick}>
            <span className='me-1 text-dark fw-bold fs-6'>
              {type_delive == 0 ? (
                <KTSVG path='/media/icons/flight.svg' className='svg-icon-primary svg-icon-3' />
              ) : (
                <KTSVG path='/media/icons/anchor.svg' className='svg-icon-primary svg-icon-3' />
              )}
            </span>
            <span className='text-dark fw-bold fs-6' ref={codeValueRef}>
              {track_code}
            </span>{' '}
          </div>{' '}
          {missing_fields && wp_data?.current?.finish !== 1 && track_package.length === 0 && (
            // (
            // <ModalNotification
            //   svg='/media/icons/duotune/general/gen044.svg'
            //   addSvgClass='svg-icon-danger'
            //   addClass='ModalNotification__button-position px-5 py-2 min-w-175px '
            //   children={
            //     <div className='fs-7 fw-normal'>
            //       <FormattedMessage id='TRACKS.PAGE.LIST.NUMBER.WARNING' />
            //       <ul className='m-0'>
            //         {falseEntries.map(([key, _]) => (
            //           <li key={key}>
            //             <FormattedMessage
            //               id={`TRACKS.PAGE.LIST.NUMBER.WARNING_${key.toUpperCase()}`}
            //             />
            //           </li>
            //         ))   }
            //         </ul>
            //       </div>
            //     }
            //   />
            // )

            <OverlayTrigger
              placement='right'
              overlay={
                <div
                  className='fs-7 fw-normal bg-white px-2 py-1'
                  style={{boxShadow: '0px 1px 5px #8f8f8f', borderRadius: '5px'}}
                >
                  <FormattedMessage id='TRACKS.PAGE.LIST.NUMBER.WARNING' />
                  <ul className='m-0'>
                    {falseEntries.map(([key, _]) => (
                      <li key={key}>
                        <FormattedMessage
                          id={`TRACKS.PAGE.LIST.NUMBER.WARNING_${key.toUpperCase()}`}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              }
            >
              <div className='me-2 cursor-pointer'>
                <KTSVG
                  path='/media/icons/duotune/general/gen044.svg'
                  className='svg-icon-3 svg-icon-danger'
                />
              </div>
            </OverlayTrigger>
          )}
          <div
            ref={target}
            onClick={() => {
              copyToClipboard(codeValueRef, setShow)
              setTimeout(() => {
                setShow(false)
              }, 1000)
            }}
          >
            <KTSVG
              path='/media/icons/duotune/general/gen054.svg'
              className={`svg-icon-3 ${
                show ? 'svg-icon-dark' : 'svg-icon-gray-600 cursor-pointer'
              }`}
            />
          </div>{' '}
          <Overlay target={target.current} show={show} placement='right'>
            {(props) => (
              <Tooltip {...props}>{intl.formatMessage({id: 'GENERAL.TOOLTIPS.COPIED'})} </Tooltip>
            )}
          </Overlay>
        </div>
        {receiver && receiver.id !== 0 ? (
          <span className='mb-1 fs-8 fw-semobold'>{`${receiver.lastName} ${receiver.firstName} ${
            receiver.patronymic || ''
          }`}</span>
        ) : (
          <></>
        )}
        {dispatch === 0 && wp_data?.current?.finish !== 1 && track_package?.length === 0 && (
          <span className='text-muted fw-semobold text-muted d-block fs-7'>
            <span className={`p-1 fs-8 fw-semobold  StatusBar__status StatusBar_blocked`}>
              <FormattedMessage id='TRACKS.PAGE.LIST.NUMBER.PROH_STATUS' />
            </span>
          </span>
        )}
      </div>
    </div>
  )
}

export {TrackNumberCell}
