import {IBuyoutFormProds} from '../../../../app/pages/buyouts/core/_models/_buyout-models'
import {ITrackFormProds} from '../../../../app/pages/tracks/core/_models/_tracks-models'

export const checkForAnyCommercialProducts = (prods: ITrackFormProds[] | IBuyoutFormProds[]) => {
  //  brand
  const isCommercialBrand = prods?.some(({brand}) => brand?.commercial && +brand?.commercial === 1)

  // qty

  const isAnyCommQty = prods?.some(
    ({name, qty}) => name?.com_qty && +name?.com_qty && +qty >= +name?.com_qty
  )

  const isCommercialQty = isAnyCommQty || isAnyTotalQuantityExceeded(prods)

  // price
  const isCommercialPrice = prods?.some(
    ({name, cost}) => name?.com_price && +name?.com_price && +cost >= +name?.com_price
  )

  const anyComProds = isCommercialBrand || isCommercialQty || isCommercialPrice
  return anyComProds
}

export const checkProductForCommercialValues = (prod: ITrackFormProds | IBuyoutFormProds) => {
  const isComProd =
    (prod?.brand?.commercial && +prod?.brand?.commercial === 1) ||
    (prod?.name?.com_price && +prod?.name?.com_price && +prod?.cost >= +prod?.name?.com_price) ||
    (prod?.name?.com_qty && +prod?.name?.com_qty && +prod?.qty >= +prod?.name?.com_qty)

  return isComProd
}

export const isAnyTotalQuantityExceeded = (prods: ITrackFormProds[] | IBuyoutFormProds[]) => {
  const productQuantities: Record<string, {total: number; com: number}> = {}

  for (const prod of prods || []) {
    if (prod?.name?.com_qty && prod?.name?.value) {
      const productId = prod.name.value
      const comQty = +prod.name.com_qty
      const totalQty = (productQuantities[productId]?.total || 0) + +prod.qty

      productQuantities[productId] = {
        com: comQty,
        total: totalQty,
      }
    }
  }

  const result = productQuantities
    ? Object.entries(productQuantities).some(([key, {total, com}]) => total >= com)
    : false

  return result
}
