import {Formik, Form} from 'formik'
import {trackPageParcelSchema} from '../../core/yup/track-page'
import {useState} from 'react'
import {CardFooter} from '../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'
import {ITrackPageParcelTab} from '../../core/_models/_track-page-models'
import {Track} from '../../core/_models/_tracks-models'
import {SettingsRadio} from '../../../../modules/custom/form-elements/radios/SettingsRadio'
import {ITariffs} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {useQueryClient} from 'react-query'
import {editTrack} from '../../core/_requests'
import {FormattedMessage, useIntl} from 'react-intl'

interface Props {
  track: Track
  setCurrTrack: (obj: Track) => void
  isArchive: boolean
  disabledRadios: boolean | undefined
}

export function TrackParcel({track, setCurrTrack, isArchive, disabledRadios}: Props) {
  const intl = useIntl()
  const {mitnitsya, insurance, track_package} = track
  const queryClient = useQueryClient()

  const initialValues = {
    package_type: mitnitsya,
    insurance: insurance === 1 ? true : false,
  }

  const tariffsListData: ITariffs | undefined = queryClient.getQueryData('tariffsList')

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleSubmit = async (values: ITrackPageParcelTab) => {
    const {package_type, insurance} = values

    setLoading(true)
    const response = await editTrack({
      ...track,
      receiver: track?.receiver?.id ? +track?.receiver?.id : null,
      mitnitsya: package_type,
      insurance: insurance === true ? 1 : 0,
      invoice_file:
        track.invoice_file.map((item) => {
          return {name: item.name, tmp_name: item.file.filename}
        }) || undefined,
    })

    if (response) {
      setCurrTrack(response)
      setLoading(false)

      setSuccess(true)

      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <Formik
        validationSchema={trackPageParcelSchema(intl)}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({values, setFieldValue}) => (
          <Form noValidate className='form'>
            {/* {JSON.stringify(values)} */}

            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6 pt-0'>
                  <FormattedMessage id='TRACKS.ADD_MODAL.FORM.TYPE_TAB' />
                </label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <SettingsRadio
                      title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.COMMERCIAL_TITLE'})}
                      disabled={disabledRadios}
                      description={
                        <>
                          <p className='text-muted fs-6 m-0'>
                            <b>
                              {` $${
                                tariffsListData
                                  ? +track.type_delive === 0
                                    ? tariffsListData.avia_com
                                    : tariffsListData.sea_com
                                  : '0'
                              }
                                /${intl.formatMessage({id: 'GENERAL.MEASURES.KG'})}`}
                            </b>
                            {intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.TARIFF_DESC'})}
                          </p>
                          <p className='text-muted fs-6 m-0'>
                            <b>{tariffsListData?.com_perc}%</b>

                            {intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.COMMERCIAL.DESC'})}
                          </p>
                        </>
                      }
                      inputName='package_type'
                      formValues={values}
                      value={1}
                      setFieldValue={setFieldValue}
                    />
                    <SettingsRadio
                      title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.NON_COMM_TITLE'})}
                      description={
                        <>
                          <p className='text-muted fs-6 m-0'>
                            <b>{` $${
                              tariffsListData
                                ? +track.type_delive === 0
                                  ? tariffsListData.avia
                                  : tariffsListData.sea
                                : '0'
                            }/${intl.formatMessage({id: 'GENERAL.MEASURES.KG'})}`}</b>{' '}
                            <FormattedMessage id='TRACKS.ADD_MODAL.FORM.TARIFF_DESC' />
                          </p>
                          <p className='text-muted fs-6 m-0'>
                            <b>
                              {` $${tariffsListData?.free_ins}/${intl.formatMessage({
                                id: 'GENERAL.MEASURES.KG',
                              })}`}
                            </b>{' '}
                            {intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.NON_COMM_DESC'})}
                          </p>
                        </>
                      }
                      disabled={disabledRadios}
                      inputName='package_type'
                      formValues={values}
                      value={0}
                      setFieldValue={setFieldValue}
                    />
                    {+values?.package_type === 0 && (
                      <InputTemplate
                        inputName='insurance'
                        type='checkbox'
                        checkboxProps={{
                          checked: values.insurance,
                          disabled:
                            isArchive === true ? true : track_package.length > 0 ? true : false,
                          setFieldValue: setFieldValue,
                          value: 1,
                          inputName: 'insurance',
                          type: 'checkbox',
                        }}
                        title={intl.formatMessage(
                          {id: 'TRACKS.ADD_MODAL.FORM.INSURANCE_TITLE'},
                          {perc: tariffsListData?.add_ins}
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {isArchive === false && !disabledRadios && (
              <CardFooter loading={loading} success={success} />
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}
