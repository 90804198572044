import {Field} from 'formik'
import {ICheckboxProps} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'

interface Props {
  props: ICheckboxProps
}
export function RadioCheckTemplate({props}: Props) {
  const {
    inputName,
    value,
    placeholder = '',
    type,
    disabled,
    checked,
    setFieldValue,
    handleChange,
  } = props

  return (
    <Field
      type={type}
      className={`form-check-input me-2`}
      name={inputName}
      value={+value}
      disabled={disabled}
      checked={checked}
      placeholder={placeholder}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (type === 'radio') {
          setFieldValue(inputName, +e.target.value)
        } else if (type === 'checkbox') {
          setFieldValue(inputName, e.target.checked)
        }

        if (handleChange) {
          handleChange()
        }
      }}
    />
  )
}
