import {ISetFieldValue} from '../../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {InputTemplate} from '../../../../form-elements/InputTemplate'
import {Field, ErrorMessage} from 'formik'
import {useEffect, useState} from 'react'
import {IBasicSelect} from '../../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {
  IShipmentAddress,
  IShipmentAddressForm,
} from '../../../../../../../_metronic/helpers/custom/tsHelpers/profileHelpers'
import {locationsApi} from '../../../../../../../_metronic/helpers/custom/api/locationsApi'
import {FormattedMessage, useIntl} from 'react-intl'

interface Props {
  setFieldValue: ISetFieldValue
  values: IShipmentAddressForm
  editing: boolean
  dataForEditing?: IShipmentAddress
  vector: boolean
}

export function AddressShipment({setFieldValue, values, editing, dataForEditing, vector}: Props) {
  // console.log('val address', values, dataForEditing)
  const intl = useIntl()
  const [citiesLoading, setCitiesLoading] = useState(false)
  const [citiesResults, setCitiesResults] = useState<IBasicSelect[]>([])
  const [streetsLoading, setStreetsLoading] = useState(false)
  const [streetsResults, setStreetsResults] = useState<IBasicSelect[]>([])

  const [streetSearchQuery, setStreetSearchQuery] = useState('')
  useEffect(() => {
    if (editing === true && dataForEditing) {
      filterCities(dataForEditing.city.name.toLowerCase())

      if (dataForEditing.address) {
        setStreetSearchQuery(dataForEditing?.address?.street.name.toLowerCase())
      }
    } else {
      filterCities('')
    }
  }, [dataForEditing, editing])

  const filterCities = async (query: string) => {
    setCitiesLoading(true)
    await locationsApi
      .getAddressCities({city: query, vector})
      .then((res) => {
        setCitiesResults(res)
      })
      .catch((err) => console.log(err))
      .finally(() => setCitiesLoading(false))
  }

  const filterStreets = async () => {
    setStreetsLoading(true)
    await locationsApi
      .getCityStreets(streetSearchQuery, values.city || '')
      .then((res) => {
        if (dataForEditing?.address?.street.id === 'null') {
          res.push({
            value: dataForEditing.address.street.id,
            label: dataForEditing.address.street.name,
          })
        }
        setStreetsResults(res)
      })
      .catch((err) => console.log(err))
      .finally(() => setStreetsLoading(false))
  }

  useEffect(() => {
    filterStreets()
  }, [values.city, streetSearchQuery])

  return (
    <>
      <div className='mb-5'>
        <InputTemplate
          inputName='city'
          required={true}
          type='select'
          value={values.city}
          title={intl.formatMessage({id: 'COMPONENTS.AddressShipment.CITY'})}
          reactSelectprops={{
            options: citiesResults,
            isLoading: citiesLoading,
            onInputChange: filterCities,
            isClearable: true,
            selectName: 'city',
            setFieldValue: setFieldValue,
            placeholder: intl.formatMessage({id: 'COMPONENTS.AddressShipment.CITY_PLACEHOLDER'}),
            currentValue: citiesResults.find((item: any) => item.value === values.city),
          }}
        />
      </div>
      <div className='mb-5'>
        <InputTemplate
          inputName='street'
          required={true}
          type='select'
          title={intl.formatMessage({id: 'COMPONENTS.AddressShipment.STREET'})}
          value={values.street}
          reactSelectprops={{
            options: streetsResults,
            isLoading: streetsLoading,
            onInputChange: setStreetSearchQuery,
            isClearable: true,
            selectName: 'street',
            setFieldValue: setFieldValue,
            placeholder: intl.formatMessage({id: 'COMPONENTS.AddressShipment.STREET_PLACEHOLDER'}),
            currentValue: streetsResults.find((item: any) => item.value === values.street),
            isCreatable: true,
            setResults: setStreetsResults,
          }}
        />
      </div>
      <div className='d-flex justify-content-around'>
        <InputTemplate
          required={true}
          type='number'
          inputName='houseNumber'
          marginClass='me-4'
          title={intl.formatMessage({id: 'COMPONENTS.AddressShipment.HOUSE'})}
        />{' '}
        <InputTemplate
          required={false}
          type='text'
          inputName='houseLetter'
          title={intl.formatMessage({id: 'COMPONENTS.AddressShipment.LETTER'})}
        />{' '}
      </div>
      <div className='d-flex w-100'>
        <div className='fv-row mb-10 me-4 w-50 '>
          {' '}
          <div className='d-flex w-100'>
            {' '}
            <label className='d-flex fs-6 fw-bold mb-2 me-2'>
              <span>
                <FormattedMessage id='COMPONENTS.AddressShipment.APARTMENT' />
              </span>
            </label>
            <Field
              className='form-check-input'
              type='radio'
              name='premisesType'
              value='apartment'
            />
          </div>
          {values.premisesType === 'apartment' && (
            <InputTemplate required={false} type='text' inputName='apartment' marginClass='me-4' />
          )}
        </div>
        <div className='fv-row mb-10 w-50'>
          {' '}
          <div className='d-flex'>
            {' '}
            <label className='d-flex align-items-center fs-6 fw-bold mb-2 me-2 '>
              <span>
                <FormattedMessage id='COMPONENTS.AddressShipment.OFFICE' />
              </span>
            </label>
            <Field className='form-check-input' type='radio' name='premisesType' value='office' />
          </div>
          {values.premisesType === 'office' && (
            <div>
              <InputTemplate required={false} type='text' inputName='office' />{' '}
            </div>
          )}
        </div>
      </div>{' '}
      <div className='text-danger'>
        <ErrorMessage name='premisesType' />
      </div>
    </>
  )
}
