import axios from 'axios'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'

export interface IWarehouseData {
  name: string
  value: string
}

export const getWarehouseData = async () => {
  const {selectedLang} = getConfig()

  const {data} = await axios.get<IWarehouseData[]>('/api/profile/storage_address', {
    headers: {
      'Accept-Language': selectedLang,
    },
  })
  return data
}
