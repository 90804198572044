/* eslint-disable jsx-a11y/anchor-is-valid */
import {Dispatch, SetStateAction, useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {Formik, Form, FormikValues} from 'formik'
import {useMutation} from 'react-query'
import {Modal} from 'react-bootstrap'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {createTrackPageContentCardSchema} from '../../../core/yup/track-page'
import {ITrackFormProds, Track} from '../../../core/_models/_tracks-models'
import {editTrack, getTrackCategories} from '../../../core/_requests'
import {
  reformOneTrackProduct,
  reformTrackProductsFromBackend,
} from '../../../../../../_metronic/helpers/custom/funcs/reformProducts'
import {FormattedMessage, useIntl} from 'react-intl'
import {CategoriesWrapper} from '../../../../../modules/custom/sections/categories/CategoriesWrapper'
import {
  checkProductForCommercialValues,
  isAnyTotalQuantityExceeded,
} from '../../../../../../_metronic/helpers/custom/funcs/checkForCommercial'

const inits: ITrackFormProds = {
  group: {value: '', label: ''},
  name: {label: '', value: '', require_invoice: 0, brands: []},
  brand: {label: '', value: ''},
  qty: 1,
  cost: '',
  total: '',
  repack: false,
  weight: '',
  used: false,
  user_name: '',
  model: '',
}
interface Props {
  setIsOpen: (bool: boolean) => void
  isOpen: boolean
  track: Track
  setCurrTrack: (obj: Track) => void
  setDisabledRadios: Dispatch<SetStateAction<boolean | undefined>>
}

const CreateTrackContentCard = ({
  setIsOpen,
  isOpen,
  track,
  setCurrTrack,
  setDisabledRadios,
}: Props) => {
  const intl = useIntl()

  const [isLoading, setIsLoading] = useState(false)

  const [initValues] = useState<ITrackFormProds>(inits)

  const createTrackContentMutation = useMutation(editTrack, {
    onMutate: () => {
      setIsLoading(true)
    },
    onSuccess: (data) => {
      if (data) {
        setCurrTrack(data)
      }
      closeModal()
    },

    onError: (error) => {
      console.log(error)
    },
    onSettled: () => {
      setIsLoading(false)
    },
  })

  const closeModal = () => {
    setIsOpen(false)
  }

  const submitStep = async (values: ITrackFormProds, actions: FormikValues) => {
    const isNewProdCom = checkProductForCommercialValues(values)

    const someOldCommercial = track.prods.some((item) => +item.commercial === 1)
    const reformedOldProds = await reformTrackProductsFromBackend(track.prods)
    const exceededQty = isAnyTotalQuantityExceeded([...reformedOldProds, values])
    const mitn = isNewProdCom === true || someOldCommercial === true || exceededQty === true ? 1 : 0

    console.log('mitn', mitn)

    const dataToSend = {
      ...track,
      receiver: track?.receiver?.id ? +track?.receiver?.id : null,
      prods: [...track.prods, {...reformOneTrackProduct(values)}],
      repack: values.repack === true ? 1 : 0,
      invoice_file:
        track.invoice_file.map((item) => {
          return {name: item.name, tmp_name: item.file.filename}
        }) || undefined,
      mitnitsya: mitn,
    }

    setDisabledRadios(mitn === 1 ? true : false)
    createTrackContentMutation.mutate(dataToSend)

    actions.resetForm()
  }

  return (
    <Modal
      className='modal fade'
      show={isOpen}
      dialogClassName='modal-dialog-centered mw-1000px'
      id='kt_modal_create_track_prod'
      aria-hidden='true'
    >
      <div className='modal-content    CreateWebsite__overflow-container'>
        <div className='modal-header'>
          <h2>
            <FormattedMessage id='TRACK_PAGE.CONTENT_TAB.GOODS_MODAL.ADD' />
          </h2>

          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            data-bs-dismiss='modal'
            onClick={closeModal}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>

        <div className='modal-body py-lg-10 px-5 px-lg-10'>
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            <Formik
              validationSchema={
                createTrackPageContentCardSchema(intl, +track.type_delive === 0 ? 'avia' : 'sea')[0]
              }
              initialValues={initValues}
              onSubmit={submitStep}
              enableReinitialize={true}
            >
              {({values, setFieldValue, errors}) => (
                <Form className='form' noValidate id='kt_modal_create_track_prod_form'>
                  {/* {JSON.stringify(errors)}
                  {JSON.stringify(values)} */}
                  <div className='current' data-kt-stepper-element='content'>
                    <div className='w-100'>
                      <div className='d-flex flex-column fv-row w-100'>
                        <CategoriesWrapper
                          inputName={{
                            groups: `group`,
                            categories: `name`,
                            brands: 'brand',
                            user_name: `user_name`,
                            model: `model`,
                          }}
                          setFieldValue={setFieldValue}
                          selectValues={{
                            groups: values.group,
                            categories: values.name,
                            brands: values.brand,
                          }}
                          apiRequest={getTrackCategories}
                          values={values}
                          delType={+track.type_delive === 0 ? 'avia' : 'sea'}
                        />
                      </div>
                      <div className='d-flex'>
                        {values.name.repack === 1 && (
                          <InputTemplate
                            inputName={`repack`}
                            type='checkbox'
                            checkboxProps={{
                              checked: values.repack || false,
                              disabled: false,
                              setFieldValue: setFieldValue,
                              value: 1,
                              inputName: `repack`,
                              type: 'checkbox',
                            }}
                            title={intl.formatMessage({
                              id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.REMOVE_BOXES',
                            })}
                          />
                        )}
                        <InputTemplate
                          inputName='used'
                          type='checkbox'
                          checkboxProps={{
                            checked: values.used,
                            disabled: false,
                            setFieldValue: setFieldValue,
                            value: 1,
                            inputName: 'used',
                            type: 'checkbox',
                          }}
                          title={intl.formatMessage({id: 'GENERAL.STATUSES.USED'})}
                        />
                      </div>
                      <div className='d-flex flex-gutter w-100'>
                        <InputTemplate
                          inputName={`qty`}
                          fieldWidth='w-50'
                          type='number'
                          required={true}
                          title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.QTY'})}
                          value={values.qty}
                          handleChange={(val: number) =>
                            setFieldValue(`total`, (val * +values.cost).toFixed())
                          }
                          numberWithControls={true}
                        />

                        <InputTemplate
                          inputName={`cost`}
                          type='number'
                          fieldWidth='w-50'
                          required={true}
                          title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.COST'})}
                          addSymbol={'$'}
                          value={values.cost}
                          handleChange={(val: number) =>
                            setFieldValue(`total`, (val * +values.qty).toFixed())
                          }
                        />
                      </div>{' '}
                      <div className='d-flex flex-gutter w-100'>
                        {values.name.require_weight && values.name.require_weight === 1 ? (
                          <InputTemplate
                            inputName={`weight`}
                            type='number'
                            fieldWidth='w-50'
                            required={true}
                            title={intl.formatMessage({
                              id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.WEIGHT',
                            })}
                            addSymbol={'кг'}
                            value={values.weight}
                          />
                        ) : (
                          <></>
                        )}
                        <InputTemplate
                          inputName={`total`}
                          addSymbol={'$'}
                          fieldWidth='w-50'
                          type='number'
                          required={true}
                          title={intl.formatMessage({
                            id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.TOTAL_COST',
                          })}
                          value={values.total}
                          handleChange={(val: number) =>
                            setFieldValue(`cost`, (val / +values.qty).toFixed(1))
                          }
                        />
                      </div>{' '}
                      {/* {checkProductForCommercialValues(values) === true && (
                        <div className='d-flex justify-content-center px-auto'>
                          <Notification
                            noteText={
                              <>
                                <h3 className='fs-6'>
                                  <FormattedMessage id='TRACKS.ADD_MODAL.FORM.COMMERCIAL.TITLE' />
                                </h3>
                                <TrackTarrifs
                                  delivery_type={
                                    currentUser?.settings?.delivery_type
                                      ? +currentUser?.settings?.delivery_type
                                      : 0
                                  }
                                  parcel_type={1}
                                />
                              </>
                            }
                          />
                        </div>
                      )} */}
                    </div>
                  </div>
                  <div className='d-flex flex-stack justify-content-end pt-10'>
                    <div>
                      <button
                        type='submit'
                        className='btn btn-lg btn-primary me-3'
                        disabled={isLoading}
                      >
                        <span className='indicator-label'>
                          <FormattedMessage id='GENERAL.BUTTONS.CREATE' />
                        </span>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {CreateTrackContentCard}
