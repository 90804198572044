/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {Link, useLocation, useParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {FormattedMessage, useIntl} from 'react-intl'
import {useAuth} from '../core/Auth'
import {getUser, reg_confirm} from '../core/_requests'

export function RegistrationEmailConfirmed() {
  let params = useParams()
  const location = useLocation()
  // @ts-ignore
  const email = location.state?.email || ''
  const intl = useIntl()
  const {saveAuth, setCurrentUser} = useAuth()
  const [status, setStatus] = useState<string | boolean>(false)

  const authUser = async (token: string) => {
    try {
      const {data: auth} = await reg_confirm(token)
      saveAuth(auth)
      const {data: user} = await getUser()
      setCurrentUser(user)
    } catch (error: any) {
      console.error(error)
      let errMsg = intl.formatMessage({id: 'ERROR'})
      if (error.response.data?.message) {
        const val = error.response.data.message
        errMsg = intl.formatMessage({id: val, defaultMessage: val})
      }
      setStatus(errMsg)
    }
  }

  useEffect(() => {
    if (params.token) {
      authUser(params.token)
    } // eslint-disable-next-line
  }, [])

  return (
    <div className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
      <div className='mb-10 text-center'>
        <div className='text-gray-400 fw-bold fs-4'>
          {params.token && (
            <>
              <h1 className='text-dark mb-3'>
                <FormattedMessage id='AUTH.EMAIL_CONFIRMED' />
              </h1>

              <div className='mb-5'>
                <Link to='/' className='btn btn-sm btn-primary'>
                  <FormattedMessage id='GENERAL.BUTTONS.RETURN_HOME' />
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
