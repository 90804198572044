import {nanoid} from 'nanoid'
import {useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {useQuery} from 'react-query'
import {getWarehouseData} from './core/_requests'
import {WarehouseItem} from './WarehouseItem'

export function Warehouse() {
  const [copied, setCopied] = useState<string[]>([])

  const {data: whAddressData} = useQuery('warehouseAddress', getWarehouseData)

  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bold m-0'>
            <FormattedMessage id='USA_WAREHOUSE.PAGE.PAGE_TITLE' />
          </h3>
        </div>
      </div>
      <div className='card-body p-9'>
        {whAddressData?.map(({name, value}) => (
          <WarehouseItem
            title={name}
            value={value}
            key={nanoid()}
            copied={copied}
            setCopied={setCopied}
          />
        ))}
      </div>
    </div>
  )
}
