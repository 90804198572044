/* eslint-disable jsx-a11y/anchor-is-valid */
import {useRef, useState} from 'react'
import {Overlay, Tooltip} from 'react-bootstrap'
import CopyToClipboard from 'react-copy-to-clipboard'
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../app/modules/auth'
import {KTSVG} from '../../../helpers'

export const HeaderUserCreds = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [copied, setCopied] = useState(false)
  const target = useRef<HTMLButtonElement | null>(null)
  const value = currentUser?.externalId
    ? `${currentUser?.firstName || ''} ${currentUser?.lastName || ''}, ${currentUser?.externalId}`
    : ''

  return (
    <>
      {(currentUser?.firstName || currentUser?.lastName || currentUser?.externalId) && (
        <div>
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.firstName} {currentUser?.lastName}
            </div>
            <div className='d-flex flex-gutter align-items-center'>
              <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
                {currentUser?.externalId}
              </a>
              <CopyToClipboard
                text={value}
                onCopy={() => {
                  setCopied(true)
                }}
              >
                <button
                  type='button'
                  ref={target}
                  className='highlight-copy btn p-0'
                  onClick={() => {
                    setCopied(true)
                    setTimeout(() => {
                      setCopied(false)
                    }, 1000)
                  }}
                >
                  <KTSVG
                    path='/media/icons/duotune/general/gen054.svg'
                    className={`svg-icon-3 ${
                      copied === true ? 'svg-icon-dark' : 'svg-icon-gray-300'
                    }`}
                  />
                </button>
              </CopyToClipboard>{' '}
              <Overlay target={target.current} show={copied} placement='bottom'>
                {(props) => (
                  <Tooltip id='overlay-example' {...props}>
                    {intl.formatMessage({id: 'GENERAL.TOOLTIPS.COPIED'})}{' '}
                  </Tooltip>
                )}
              </Overlay>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
