/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useMutation} from 'react-query'
import {Link} from 'react-router-dom'
import {ID, KTSVG} from '../../../../../../../_metronic/helpers'
import {Portal} from '../../../../../../../_metronic/partials'
import {ActionConfirmation} from '../../../../../../modules/custom/modals/ActionConfirmation'
import {useListView} from '../../../../core/ListViewProvider'
import {useQueryResponse} from '../../../../core/QueryResponseProvider'
import {archiveTrack} from '../../../../core/_requests'
import {useIntl} from 'react-intl'
import {Track} from '../../../../core/_models/_tracks-models'

type Props = {
  track: Track
}

const TrackActionsCell: FC<Props> = ({track}: Props) => {
  const intl = useIntl()
  const {refetch} = useQueryResponse()
  const {isArchive} = useListView()

  const [modalOpen, setModalOpen] = useState(false)

  const archiveMutation = useMutation<any, Error>(
    async () => {
      return await archiveTrack(track.id, isArchive === true ? 0 : 1)
    },
    {
      onSuccess: () => {},
      onSettled: () => {
        setModalOpen(false)
        refetch()
      },
      onError: (error) => {
        console.log(error)
      },
    }
  )
  return (
    <div className='d-flex flex-gutter justify-content-end flex-shrink-0'>
      {isArchive === true ? (
        <>
          {' '}
          {modalOpen && (
            <Portal
              children={
                <ActionConfirmation
                  actionName={intl.formatMessage({id: 'TRACKS.PAGE.LIST.ACTIONS.RESTORE_CONF'})}
                  setModalOpen={setModalOpen}
                  handleAction={() => archiveMutation.mutate()}
                  id={track.id}
                  isOpen={modalOpen}
                />
              }
              className='modal-root'
            />
          )}
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip>{intl.formatMessage({id: 'TRACKS.PAGE.LIST.ACTIONS.VIEW'})}</Tooltip>}
          >
            <Link
              to={`/tracks/info/overview/${track.id}`}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-3' />
            </Link>
          </OverlayTrigger>
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip>{intl.formatMessage({id: 'TRACKS.PAGE.LIST.ACTIONS.RESTORE'})}</Tooltip>
            }
          >
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => setModalOpen(true)}
            >
              <KTSVG path='/media/icons/duotune/files/fil018.svg' className='svg-icon-3' />
            </button>
          </OverlayTrigger>
        </>
      ) : (
        <>
          {' '}
          {modalOpen && (
            <Portal
              children={
                <ActionConfirmation
                  actionName={intl.formatMessage({id: 'TRACKS.PAGE.LIST.ACTIONS.TRASH_CONF'})}
                  setModalOpen={setModalOpen}
                  handleAction={() => archiveMutation.mutate()}
                  id={track.id}
                  isOpen={modalOpen}
                />
              }
              className='modal-root'
            />
          )}
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip>{intl.formatMessage({id: 'GENERAL.BUTTONS.EDIT'})}</Tooltip>}
          >
            <Link
              to={`/tracks/edit/overview/${track.id}`}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
            </Link>
          </OverlayTrigger>
          {track.allow?.archive === 1 && (
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip>{intl.formatMessage({id: 'TRACKS.PAGE.LIST.ACTIONS.TRASH'})}</Tooltip>
              }
            >
              <a
                href='#'
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                onClick={() => setModalOpen(true)}
              >
                <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
              </a>
            </OverlayTrigger>
          )}
          <a
            href='#'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm show-chat'
            data-id={track.id}
            data-type='TrackingNumber'
            data-name={track.track_code}
          >
            {/*<KTSVG path='/media/icons/duotune/communication/com003.svg' className='svg-icon-3' />*/}
          </a>
        </>
      )}
    </div>
  )
}

export {TrackActionsCell}
