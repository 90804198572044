import {Navigate, Routes, Route, Outlet, useLocation} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {TrackHeader} from './header/TrackHeader'
import {useQuery} from 'react-query'
import {TrackOverview} from './overview/TrackOverview'
import {TrackContent} from './content/TrackContent'
import {TrackReceiver} from './receiver/TrackReceiver'
import {TrackInvoice} from './invoice/TrackInvoice'
import {TrackInspection} from './inspection/TrackInspection'
import {TrackStatus} from './status/TrackStatus'
import {getTrackBrandsByCat, getTrackById} from '../core/_requests'
import {useEffect, useState} from 'react'
import {ITrackFormProds, Track} from '../core/_models/_tracks-models'
import {TrackParcel} from './parcel/TrackParcel'
import {FormattedMessage, useIntl} from 'react-intl'
import {AllTrackParcels} from './all-parcels/AllTrackParcels'
import {GoodsBrandsQueryResponse} from '../../../../_metronic/helpers/custom/tsHelpers/categoriesHelpers'
import {checkForAnyCommercialProducts} from '../../../../_metronic/helpers/custom/funcs/checkForCommercial'

interface Props {
  id: string | number
}

const TrackPage = ({id}: Props) => {
  const {data: trackById} = useQuery('track', () => {
    return getTrackById(id)
  })

  const intl = useIntl()

  const {pathname} = useLocation()

  const [currTrack, setCurrTrack] = useState<Track | null>(null)
  const [disabledRadios, setDisabledRadios] = useState<boolean | undefined>(
    trackById?.prods.some((item) => +item.commercial === 1)
  )

  const isArchive = pathname.includes('info') || currTrack?.delivered === 1 ? true : false

  // console.log('isa', pathname, isArchive)
  const [goodsFields, setGoodsFields] = useState<ITrackFormProds[]>([])

  const getGoodsFields = async (track: Track) => {
    let fields: ITrackFormProds[] = []

    const promises = track.prods.map(
      async ({qty, cost, total, wgt, category, brand, group, used, model, user_name}) => {
        let brands: GoodsBrandsQueryResponse = []

        if (category?.id) brands = await getTrackBrandsByCat(category?.id)
        return {
          group: {label: group?.name || '', value: group?.id || ''},
          name: {
            ...category,
            label: category?.name,
            value: category?.id,
            require_invoice: +category?.require_invoice,
            brands,
          },
          brand: {...brand, label: brand?.name || '', value: brand?.id || ''},
          qty,
          cost,
          total,
          repack: false,
          weight: wgt || '',
          used: +used === 1 ? true : false,
          model: model || '',
          user_name: user_name || '',
        }
      }
    )

    fields = await Promise.all(promises)

    setGoodsFields(fields)
  }

  useEffect(() => {
    if (trackById) {
      setCurrTrack(trackById)
      getGoodsFields(trackById)
    }
  }, [trackById])

  useEffect(() => {
    const anyComProds = checkForAnyCommercialProducts(goodsFields)

    const bool =
      isArchive === true ||
      anyComProds === true ||
      (currTrack?.track_package && currTrack?.track_package?.length > 0)
    setDisabledRadios(bool || false)
  }, [goodsFields, isArchive])

  return (
    <>
      {currTrack !== null ? (
        <>
          {currTrack && (
            <Routes>
              <Route
                element={
                  <>
                    <TrackHeader track={currTrack} isArchive={isArchive} />
                    <Outlet />
                  </>
                }
              >
                <Route
                  path={`overview/:id`}
                  element={
                    <>
                      <PageTitle>{intl.formatMessage({id: 'TRACK_PAGE.GEN_TAB'})}</PageTitle>
                      <TrackOverview
                        track={currTrack}
                        setCurrTrack={setCurrTrack}
                        isArchive={isArchive}
                      />
                    </>
                  }
                />
                <Route
                  path={`parcel/:id`}
                  element={
                    <>
                      <PageTitle>{intl.formatMessage({id: 'TRACK_PAGE.TYPE_TAB'})}</PageTitle>
                      <TrackParcel
                        track={currTrack}
                        setCurrTrack={setCurrTrack}
                        isArchive={isArchive}
                        disabledRadios={disabledRadios}
                      />
                    </>
                  }
                />
                <Route
                  path={`content/:id`}
                  element={
                    <>
                      <PageTitle>
                        {intl.formatMessage(
                          {id: 'TRACK_PAGE.CONTENT_TAB'},
                          {qty: currTrack.prods.length}
                        )}
                      </PageTitle>
                      <TrackContent
                        track={currTrack}
                        setCurrTrack={setCurrTrack}
                        isArchive={isArchive}
                        setDisabledRadios={setDisabledRadios}
                      />
                    </>
                  }
                />
                <Route
                  path={`invoice/:id`}
                  element={
                    <>
                      <PageTitle>{intl.formatMessage({id: 'TRACK_PAGE.INVOICE_TAB'})}</PageTitle>
                      <TrackInvoice
                        track={currTrack}
                        setCurrTrack={setCurrTrack}
                        isArchive={isArchive}
                      />
                    </>
                  }
                />

                <Route
                  path={`control/:id`}
                  element={
                    <>
                      <PageTitle>{intl.formatMessage({id: 'TRACK_PAGE.INSPECTION_TAB'})}</PageTitle>
                      <TrackInspection
                        track={currTrack}
                        setCurrTrack={setCurrTrack}
                        isArchive={isArchive}
                      />
                    </>
                  }
                />
                <Route
                  path={`status/:id`}
                  element={
                    <>
                      <PageTitle>{intl.formatMessage({id: 'TRACK_PAGE.STATUSES_TAB'})}</PageTitle>
                      <TrackStatus track={currTrack} />
                    </>
                  }
                />
                <Route
                  path={`all-parcels/:id`}
                  element={
                    <>
                      <PageTitle>{intl.formatMessage({id: 'TRACK_PAGE.PARCELS_TAB'})}</PageTitle>
                      <AllTrackParcels track={currTrack} />
                    </>
                  }
                />

                <Route
                  path={`receiver/:id`}
                  element={
                    <>
                      <PageTitle>{intl.formatMessage({id: 'TRACK_PAGE.RECEIVER_TAB'})}</PageTitle>
                      <TrackReceiver
                        track={currTrack}
                        setCurrTrack={setCurrTrack}
                        isArchive={isArchive}
                      />
                    </>
                  }
                />
                <Route index element={<Navigate to={`overview/${id}`} />} />
              </Route>
            </Routes>
          )}
        </>
      ) : (
        <FormattedMessage id='GENERAL.BUTTONS.WAIT' />
      )}
    </>
  )
}

export default TrackPage
