import {FormattedMessage} from 'react-intl'
import {Link, useLocation} from 'react-router-dom'
import {scrollToTabsStart} from '../../../../_metronic/helpers/custom/funcs/scrollToTabsStart'

export function ProfileHeaderNav() {
  const location = useLocation()
  return (
    <div className='d-flex overflow-auto h-55px mb-5 mb-md-0' id='item-page-header'>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
        <li className='nav-item' onClick={scrollToTabsStart}>
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (location.pathname === `/profile/overview` && 'active')
            }
            to={`/profile/overview`}
          >
            <FormattedMessage id='PROFILE.PAGE.PAGE_TITLE' />
          </Link>
        </li>
        <li className='nav-item' onClick={scrollToTabsStart}>
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (location.pathname === `/profile/contacts` && 'active')
            }
            to={`/profile/contacts`}
          >
            <FormattedMessage id='PROFILE.PAGE.CONTACTS.TITLE' />
          </Link>
        </li>
        <li className='nav-item' onClick={scrollToTabsStart}>
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (location.pathname === `/profile/shipment` && 'active')
            }
            to={`/profile/shipment`}
          >
            <FormattedMessage id='PROFILE.PAGE.ADDRESS.TITLE' />
          </Link>
        </li>
        <li className='nav-item' onClick={scrollToTabsStart}>
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (location.pathname === `/profile/social-media` && 'active')
            }
            to={`/profile/social-media`}
          >
            <FormattedMessage id='PROFILE.PAGE.SOCIAL_MEDIA.TITLE' />
          </Link>
        </li>
        <li className='nav-item' onClick={scrollToTabsStart}>
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (location.pathname === `/profile/referal` && 'active')
            }
            to={`/profile/referal`}
          >
            <FormattedMessage id='PROFILE.PAGE.REFERAL.TITLE' />
          </Link>
        </li>
        <li className='nav-item' onClick={scrollToTabsStart}>
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (location.pathname === `/profile/password` && 'active')
            }
            to={`/profile/password`}
          >
            <FormattedMessage id='PROFILE.PAGE.PASSWORD.TITLE' />
          </Link>
        </li>
        <li className='nav-item' onClick={scrollToTabsStart}>
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (location.pathname === `/profile/settings` && 'active')
            }
            to={`/profile/settings`}
          >
            <FormattedMessage id='PROFILE.PAGE.SETTINGS.TITLE' />
          </Link>
        </li>
      </ul>
    </div>
  )
}
