import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'
import {AddTrackForm, ITrackFormProds} from '../../core/_models/_tracks-models'
import {FormattedMessage, useIntl} from 'react-intl'
import {getTrackCategories} from '../../core/_requests'
import {CategoriesWrapper} from '../../../../modules/custom/sections/categories/CategoriesWrapper'
import {useEffect, useMemo} from 'react'
import {Notification} from '../../../../modules/custom/Notification'
import {TrackTarrifs} from '../TrackTarrifs'
import {
  checkForAnyCommercialProducts,
  checkProductForCommercialValues,
} from '../../../../../_metronic/helpers/custom/funcs/checkForCommercial'
import {usePageData} from '../../../../../_metronic/layout/core'
import {useListView} from '../../core/ListViewProvider'
import {useFormikContext} from 'formik'

interface Props {
  item: ITrackFormProds
  index: number
  arrayHelpers: any
  prods: ITrackFormProds[]
  setFieldValue: ISetFieldValue
  values: AddTrackForm
}

export function TrackGoodsItemBody({
  item,
  index,
  arrayHelpers,
  prods,
  setFieldValue,
  values,
}: Props) {
  const intl = useIntl()
  const {itemIdForUpdate} = useListView()
  const isCommercialProduct = useMemo(() => checkForAnyCommercialProducts(prods), [prods])

  const weightIsNeeded = useMemo(
    () => item.name.require_weight && +item.name.require_weight === 1,
    [item]
  )

  useEffect(() => {
    if (isCommercialProduct === true) {
      setFieldValue('package_type', 1)
      setFieldValue('require_invoice', 1)
    } else {
      if (!itemIdForUpdate) {
        setFieldValue('package_type', 0)
      }
      if (item.name.require_invoice === 1) {
        setFieldValue('require_invoice', 1)
      } else {
        setFieldValue('require_invoice', 0)
      }
      return
    }
  }, [isCommercialProduct])

  return (
    <>
      <div className='d-flex flex-column fv-row w-100'>
        <CategoriesWrapper
          inputName={{
            groups: `prods.${index}.group`,
            categories: `prods.${index}.name`,
            brands: `prods.${index}.brand`,
            user_name: `prods.${index}.user_name`,
            model: `prods.${index}.model`,
          }}
          setFieldValue={setFieldValue}
          selectValues={{groups: item.group, categories: item.name, brands: item.brand}}
          apiRequest={getTrackCategories}
          disabled={!!itemIdForUpdate}
          values={item}
          delType={+values.delivery_type === 0 ? 'avia' : 'sea'}
        />
      </div>
      <div className='d-flex'>
        {' '}
        {(item.name.repack === 1 || +item.repack === 1) && (
          <InputTemplate
            inputName={`prods.${index}.repack`}
            type='checkbox'
            checkboxProps={{
              checked: item.repack,
              disabled: !!itemIdForUpdate,
              setFieldValue: setFieldValue,
              value: 1,
              inputName: `prods.${index}.repack`,
              type: 'checkbox',
            }}
            title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.REMOVE_BOXES'})}
          />
        )}
        <InputTemplate
          inputName={`prods.${index}.used`}
          type='checkbox'
          checkboxProps={{
            checked: item.used,
            disabled: !!itemIdForUpdate,
            setFieldValue: setFieldValue,
            value: 1,
            inputName: `prods.${index}.used`,
            type: 'checkbox',
          }}
          title={intl.formatMessage({id: 'GENERAL.STATUSES.USED'})}
        />
      </div>
      <div className='d-flex flex-gutter w-100'>
        <InputTemplate
          inputName={`prods.${index}.qty`}
          fieldWidth='w-50'
          disabled={!!itemIdForUpdate}
          type='number'
          required={true}
          title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.QTY'})}
          value={item.qty}
          handleChange={(val: number) => {
            setFieldValue(`prods.${index}.total`, (val * +item.cost).toFixed())
          }}
          numberWithControls={true}
        />

        <InputTemplate
          inputName={`prods.${index}.cost`}
          type='number'
          fieldWidth='w-50'
          required={true}
          disabled={!!itemIdForUpdate}
          title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.COST'})}
          addSymbol={'$'}
          value={item.cost}
          handleChange={(val: number) => {
            setFieldValue(`prods.${index}.total`, (val * +item.qty).toFixed())
          }}
        />
      </div>{' '}
      <div className='d-flex flex-gutter w-100 align-items-center'>
        {weightIsNeeded === true && (
          <InputTemplate
            inputName={`prods.${index}.weight`}
            type='number'
            fieldWidth='w-50'
            required={true}
            disabled={!!itemIdForUpdate}
            title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.WEIGHT'})}
            addSymbol={'кг'}
            value={item.weight}
          />
        )}{' '}
        <InputTemplate
          inputName={`prods.${index}.total`}
          addSymbol={'$'}
          type='number'
          fieldWidth='w-50'
          required={true}
          disabled={!!itemIdForUpdate}
          title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.TOTAL_COST'})}
          value={item.total}
          handleChange={(val: number) => {
            setFieldValue(`prods.${index}.cost`, (val / +item.qty).toFixed(1))
          }}
        />
        {prods.length > 1 && !itemIdForUpdate && (
          <div className='d-flex justify-content-center w-50'>
            {' '}
            <button
              className='btn btn-sm btn-secondary d-flex align-items-center'
              type='button'
              onClick={() => arrayHelpers.remove(index)}
            >
              <FormattedMessage id='GENERAL.BUTTONS.DELETE' />
            </button>
          </div>
        )}
      </div>{' '}
      {/* {pageCustomData?.trackFormSimplified === false && isCommercialProduct === true && (
        <div className='d-flex justify-content-center px-auto'>
          <Notification
            noteText={
              <>
                <h3 className='fs-6'>
                  <FormattedMessage id='TRACKS.ADD_MODAL.FORM.COMMERCIAL.TITLE' />
                </h3>
                <TrackTarrifs delivery_type={+values.delivery_type} parcel_type={1} />
              </>
            }
          />
        </div>
      )} */}
    </>
  )
}
