import * as Yup from 'yup'
import {latinAndNumbersOnly} from '../../../../../_metronic/helpers/custom/regexs'
import {ICatBrandSelectOption} from '../../../../../_metronic/helpers/custom/tsHelpers/categoriesHelpers'
import {object_shape_basic_select} from '../../../../../_metronic/helpers/custom/yup/partials/generalPartials'
export const createBuyoutSchema = (intl: any, delType: 'avia' | 'sea') => [
  Yup.object({
    delivery_type: Yup.number().required().label('Тип доставки'),
    dispatch: Yup.boolean().required().label('Тип отправки'),
    discountNumber: Yup.string().label('Купон на скидку'),
    description: Yup.string().label('Комментарий'),
    // conditionsAccepted: Yup.boolean()
    //   .oneOf([true], 'Подтвердите согласие с условиями выкупа')
    //   .required('Подтвердите согласие с условиями выкупа'),
  }),
  Yup.object({
    prods: Yup.array()
      .of(
        Yup.object().shape({
          group: Yup.object()
            .shape({
              label: Yup.string(),
              value: Yup.string(),
            })

            .label('Группа товара'),
          name: Yup.object()
            .shape({
              label: Yup.string()
                .required(intl.messages.YUP_VALIDATION_TRACK_CHOOSE_CATEGORY)
                .notOneOf([''], intl.messages.YUP_VALIDATION_TRACK_CHOOSE_CATEGORY),
              value: Yup.string()
                .required(intl.messages.YUP_VALIDATION_TRACK_CHOOSE_CATEGORY)
                .notOneOf([''], intl.messages.YUP_VALIDATION_TRACK_CHOOSE_CATEGORY),
              brands: Yup.array(),
              require_weight: Yup.number(),
              require_name: Yup.object().shape({avia: Yup.number(), sea: Yup.number()}),
            })
            .required(intl.messages.YUP_VALIDATION_TRACK_CHOOSE_CATEGORY)
            .label('Категория товара'),
          user_name: Yup.string().when(`name.require_name[${delType}]`, {
            is: 1,
            then: Yup.string()
              .test('not-url', intl.messages.YUP_VALIDATION_USER_NAME_NOT_URL, function (value) {
                if (Yup.string().url().isValidSync(value)) {
                  return false
                }
                return true
              })
              .notOneOf([''], intl.messages.YUP_VALIDATION_INDICATE_USER_NAME)
              .matches(
                latinAndNumbersOnly,
                intl.messages.YUP_VALIDATION_GENERAL_LATIN_AND_NUMBERS_ONLY
              )
              .required(intl.messages.YUP_VALIDATION_INDICATE_USER_NAME),
          }),
          brand: object_shape_basic_select
            .when('name.brands', {
              is: (brands: ICatBrandSelectOption[]) => brands?.length > 0,
              then: Yup.object().shape({
                label: Yup.string()
                  .required(intl.messages.YUP_VALIDATION_TRACK_CHOOSE_BRAND)
                  .notOneOf([''], intl.messages.YUP_VALIDATION_TRACK_CHOOSE_BRAND),
                value: Yup.string()
                  .required(intl.messages.YUP_VALIDATION_TRACK_CHOOSE_BRAND)
                  .notOneOf([''], intl.messages.YUP_VALIDATION_TRACK_CHOOSE_BRAND),
                require_model: Yup.number(),
              }),
              otherwise: object_shape_basic_select,
            })
            .label('Бренд товару'),
          model: Yup.string(),
          used: Yup.boolean().required(),
          check_control: Yup.boolean().label('Проверка выкупа'),
          check_control_desc: Yup.string().when('check_control', {
            is: true,
            then: Yup.string().required(intl.messages.YUP_VALIDATION_TRACK_ENTER_INSPECTION_DESC),
          }),
          link: Yup.string()
            .url(intl.messages.YUP_VALIDATION_URL)
            .required(intl.messages.YUP_VALIDATION_ENTER_LINK_TO_GOODS)
            .notOneOf([''], intl.messages.YUP_VALIDATION_ENTER_LINK_TO_GOODS),
          alt_link: Yup.string().url(intl.messages.YUP_VALIDATION_URL),
          sku: Yup.string(),
          size: Yup.string(),
          color: Yup.string(),
          qty: Yup.number()
            .required(intl.messages.YUP_VALIDATION_TRACK_ENTER_QTY)
            .min(1, intl.messages.YUP_VALIDATION_TRACK_AT_LEAST_ONE_GOODS_ITEM)
            .label('Количество позиций'),
          cost: Yup.number()
            .required(intl.messages.YUP_VALIDATION_TRACK_ENTER_PRICE)
            .min(0.1, intl.messages.YUP_VALIDATION_GENERAL_NO_ZERO_OR_NEG)
            .label('Стоимость одной позиции'),
          weight: Yup.number()
            .min(0.1, intl.messages.YUP_VALIDATION_GENERAL_NO_ZERO_OR_NEG)
            .when('name.require_weight', {
              is: 1,
              then: Yup.number()
                .required(intl.messages.YUP_VALIDATION_TRACK_ENTER_WEIGHT)
                .label('Вага'),
            }),
          total: Yup.number()
            .required(intl.messages.YUP_VALIDATION_TRACK_ENTER_TOTAL)
            .min(0.1, intl.messages.YUP_VALIDATION_GENERAL_NO_ZERO_OR_NEG)
            .label('Суммарная стоимость'),
        })
      )
      .required(intl.messages.YUP_VALIDATION_TRACK_AT_LEAST_A_GOODS)
      .min(1, intl.messages.YUP_VALIDATION_TRACK_AT_LEAST_A_GOODS)
      .label('Товары'),
  }),
]
