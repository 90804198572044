/* eslint-disable jsx-a11y/anchor-is-valid */
import {TracksListToolbar} from './TracksListToolbar'
import {TracksListGrouping} from './TracksListGrouping'
import {TracksListSearchComponent} from './TracksListSearchComponent'
import {useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {useMutation} from 'react-query'
import {setAllTracks} from '../../../core/_requests'
import {useQueryResponse} from '../../../core/QueryResponseProvider'
import {ActionConfirmation} from '../../../../../modules/custom/modals/ActionConfirmation'
import {Portal} from '../../../../../../_metronic/partials'
import {FormattedMessage, useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {usePageData} from '../../../../../../_metronic/layout/core'

const TracksListHeader = () => {
  const intl = useIntl()
  const {addNotification} = usePageData()

  const {type} = useParams()
  const [isLoading, setIsLoading] = useState(false)

  const {refetch, response} = useQueryResponse()
  const [tracksToSet, setTracksToSet] = useState({type: 2, number: 0})
  const [tracksStatusToSet, setTracksStatusToSet] = useState(2)

  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  const setAllTracksMutation = useMutation(setAllTracks, {
    onMutate: () => {
      setIsLoading(true)
    },
    onSuccess: (data) => {
      if (tracksStatusToSet === 1 && data.success === true) {
        if (data.affected === tracksToSet.number) {
          addNotification({
            status: 'success',
            body: 'TRACKS.PAGE.LIST.HEADER.ALLOW_ALL.SUCCESS_NOTIFICATION',
          })
        } else {
          addNotification({
            status: 'warning',
            body: 'TRACKS.PAGE.LIST.HEADER.ALLOW_ALL.WARNING_NOTIFICATION',
            intlValues: {act: data.affected, total: tracksToSet.number},
          })
        }
      }
      if (data) {
        refetch()
      }
    },

    onError: (error) => {
      console.log(error)
    },
    onSettled: () => {
      setIsLoading(false)
      setConfirmModalOpen(false)
    },
  })

  return (
    <div className='card-header border-0 pt-6 justify-content-between align-items-start'>
      {confirmModalOpen && (
        <Portal
          children={
            <ActionConfirmation
              customText={intl.formatMessage(
                {
                  id:
                    tracksStatusToSet === 1
                      ? 'TRACKS.PAGE.LIST.HEADER.ALLOW_ALL.ACTION'
                      : 'TRACKS.PAGE.LIST.HEADER.PROH_ALL.ACTION',
                },
                {qty: tracksToSet.number}
              )}
              setModalOpen={setConfirmModalOpen}
              handleAction={() =>
                setAllTracksMutation.mutate({tracksToSet: tracksToSet.type, tracksStatusToSet})
              }
              isOpen={confirmModalOpen}
            />
          }
          className='modal-root'
        />
      )}
      <TracksListSearchComponent />
      {/* {pathname.includes('/air') && (
        <div className='d-flex flex-column flex-col-gutter'>
          <div
            className={`btn btn-sm btn-active-color-success StatusBar__status StatusBar_active  px-2`}
            onClick={() => {
              setConfirmModalOpen(true)
              setTracksToSet(0)
              setTracksStatusToSet(1)
            }}
          >
            <KTSVG path='/media/icons/flight.svg' className='svg-icon-success svg-icon-3 me-1' />
            <FormattedMessage id='TRACKS.PAGE.LIST.HEADER.ALLOW_ALL.AVIA' />
          </div>{' '}
          <div
            className='btn btn-sm btn-active-color-danger StatusBar__status StatusBar_blocked px-2'
            onClick={() => {
              setConfirmModalOpen(true)
              setTracksToSet(0)
              setTracksStatusToSet(0)
            }}
          >
            <KTSVG path='/media/icons/flight.svg' className='svg-icon-danger svg-icon-3 me-1' />
            <FormattedMessage id='TRACKS.PAGE.LIST.HEADER.PROH_ALL.AVIA' />
          </div>
        </div>
      )}
      {pathname.includes('/sea') && (
        <div className='d-flex flex-column flex-col-gutter'>
          {' '}
          <div
            className={`btn btn-sm btn-active-color-success StatusBar__status StatusBar_active  px-2`}
            onClick={() => {
              setConfirmModalOpen(true)
              setTracksToSet(1)
              setTracksStatusToSet(1)
            }}
          >
            <KTSVG path='/media/icons/anchor.svg' className='svg-icon-succcess svg-icon-3 me-1' />
            <FormattedMessage id='TRACKS.PAGE.LIST.HEADER.ALLOW_ALL.SEA' />
          </div>{' '}
          <div
            className='btn btn-sm btn-active-color-danger StatusBar__status StatusBar_blocked px-2'
            onClick={() => {
              setConfirmModalOpen(true)
              setTracksToSet(1)
              setTracksStatusToSet(0)
            }}
          >
            <KTSVG path='/media/icons/anchor.svg' className='svg-icon-danger svg-icon-3 me-1' />
            <FormattedMessage id='TRACKS.PAGE.LIST.HEADER.PROH_ALL.SEA' />
          </div>
        </div>
      )} */}
      {type === 'air' && (
        <div className='d-flex flex-column flex-col-gutter'>
          {!!response?.counters?.awaiting && (
            <div
              className={`btn btn-sm  btn-active-color-success StatusBar__status StatusBar_active  px-2`}
              onClick={() => {
                setConfirmModalOpen(true)
                setTracksToSet({type: 0, number: response?.counters?.awaiting || 0})
                setTracksStatusToSet(1)
              }}
            >
              <KTSVG path='/media/icons/flight.svg' className='svg-icon-success svg-icon-3 me-1' />
              <FormattedMessage
                id='TRACKS.PAGE.LIST.HEADER.ALLOW_ALL'
                values={{items: response?.counters?.awaiting}}
              />
            </div>
          )}
          {!!response?.counters?.allowed && (
            <div
              className='btn btn-sm btn-active-color-danger StatusBar__status StatusBar_blocked px-2'
              onClick={() => {
                setConfirmModalOpen(true)
                setTracksToSet({type: 0, number: response?.counters?.allowed || 0})
                setTracksStatusToSet(0)
              }}
            >
              <KTSVG path='/media/icons/flight.svg' className='svg-icon-danger svg-icon-3 me-1' />
              <FormattedMessage
                id='TRACKS.PAGE.LIST.HEADER.PROH_ALL'
                values={{items: response?.counters?.allowed}}
              />
            </div>
          )}
        </div>
      )}
      {type === 'sea' && (
        <div className='d-flex flex-column flex-col-gutter'>
          {' '}
          {!!response?.counters?.awaiting && (
            <div
              className={`btn btn-sm btn-active-color-success StatusBar__status StatusBar_active px-2`}
              onClick={() => {
                setConfirmModalOpen(true)
                setTracksToSet({type: 1, number: response?.counters?.awaiting || 0})
                setTracksStatusToSet(1)
              }}
            >
              <KTSVG path='/media/icons/anchor.svg' className='svg-icon-success svg-icon-3 me-1' />
              <FormattedMessage
                id='TRACKS.PAGE.LIST.HEADER.ALLOW_ALL'
                values={{items: response?.counters?.awaiting}}
              />
            </div>
          )}
          {!!response?.counters?.allowed && (
            <div
              className='btn btn-sm btn-active-color-danger StatusBar__status StatusBar_blocked px-2'
              onClick={() => {
                setConfirmModalOpen(true)
                setTracksToSet({type: 1, number: response?.counters?.allowed || 0})
                setTracksStatusToSet(0)
              }}
            >
              <KTSVG path='/media/icons/anchor.svg' className='svg-icon-danger svg-icon-3 me-1' />
              <FormattedMessage
                id='TRACKS.PAGE.LIST.HEADER.PROH_ALL'
                values={{items: response?.counters?.allowed}}
              />
            </div>
          )}
        </div>
      )}

      {/* <div className='card-toolbar'>
        {selected.length > 0 ? (
          <TracksListGrouping />
        ) : (
          <TracksListToolbar
            warehouseTracks={warehouseTracks}
            setWarehouseTracks={setWarehouseTracks}
            unpackedTracks={unpackedTracks}
            setUnpackedTracks={setUnpackedTracks}
          />
        )}
      </div> */}
    </div>
  )
}

export {TracksListHeader}
