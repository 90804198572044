import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {useQuery} from 'react-query'
import {useState} from 'react'
import {ProfileOverview} from './components/overview/ProfileOverview'
import {ProfileContacts} from './components/contacts/ProfileContacts'
import {useAuth} from '../../modules/auth'
import {ProfileShipment} from './components/shipment/ProfileShipment'
import {ProfileHeader} from './header/ProfileHeader'
import {ProfileSocialMedia} from './components/media/ProfileSocialMedia'
import {ProfileReferal} from './components/referal/ProfileReferal'
import {Settings} from './components/settings/Settings'
import {profileApi} from '../../../_metronic/helpers/custom/api/profileApi'
import {useIntl} from 'react-intl'
import {ProfilePassword} from './components/password/ProfilePassword'

const profileBreadCrumbs: Array<PageLink> = [
  // {
  //   title: 'Profile',
  //   path: 'overview',
  //   isSeparator: false,
  //   isActive: false,
  // },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProfilePage = () => {
  const {currentUser, setCurrentUser} = useAuth()
  const [searchQuery, setSearchQuery] = useState('')
  const intl = useIntl()

  const {data: profileAddressesData} = useQuery('profileAddresses', profileApi.getProfileAddresses)

  const {data: referalData} = useQuery('profileReferal', profileApi.fetchReferals)

  return (
    <>
      {currentUser && (
        <Routes>
          <Route
            element={
              <>
                <ProfileHeader user={currentUser} />
                <Outlet />
              </>
            }
          >
            <Route
              path={`overview`}
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>
                    {intl.formatMessage({id: 'PROFILE.PAGE.PAGE_TITLE'})}
                  </PageTitle>
                  <ProfileOverview user={currentUser} setCurrentUser={setCurrentUser} />
                </>
              }
            />
            <Route
              path={`contacts`}
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>
                    {intl.formatMessage({id: 'PROFILE.PAGE.PAGE_TITLE'})}
                  </PageTitle>
                  <ProfileContacts user={currentUser} setCurrentUser={setCurrentUser} />
                </>
              }
            />
            <Route
              path={`shipment`}
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>
                    {intl.formatMessage({id: 'PROFILE.PAGE.PAGE_TITLE'})}
                  </PageTitle>
                  <ProfileShipment
                    addresses={profileAddressesData}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                  />
                </>
              }
            />

            <Route
              path={`social-media`}
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>
                    {intl.formatMessage({id: 'PROFILE.PAGE.PAGE_TITLE'})}
                  </PageTitle>
                  <ProfileSocialMedia />
                </>
              }
            />
            <Route
              path={`referal`}
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>
                    {intl.formatMessage({id: 'PROFILE.PAGE.PAGE_TITLE'})}
                  </PageTitle>
                  <ProfileReferal referalData={referalData} />
                </>
              }
            />

            <Route
              path={`settings`}
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>
                    {intl.formatMessage({id: 'PROFILE.PAGE.PAGE_TITLE'})}
                  </PageTitle>
                  {/*@ts-ignore*/}
                  <Settings settings={currentUser?.settings} />
                </>
              }
            />
            <Route
              path={`password`}
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>
                    {intl.formatMessage({id: 'PROFILE.PAGE.PAGE_TITLE'})}
                  </PageTitle>
                  <ProfilePassword />
                </>
              }
            />
            <Route index element={<Navigate to={`overview`} />} />
          </Route>
        </Routes>
      )}
    </>
  )
}

export default ProfilePage
